import React, { useState } from 'react'
import Results from './Results'
import "./IPInput.css"
import { useSearchParams} from 'react-router-dom';

var IpSubnetCalculator = require( 'ip-subnet-calculator' );

const IPInput = (props) => {
    const good_color = "#8CD47E";
    const bad_color = "#FF6961";
    const [ip, setCurrentIP] = useState (props.ip);
    const [cidr, setCurrentCIDR] = useState (props.cidr);
    const [ip_color, setIPColor] = useState (good_color);
    const [cidr_color, setCIDRColor] = useState (good_color);
    const [searchParams, setSearchParams] = useSearchParams();

    const handleIPChange = (event) => {
        if(IpSubnetCalculator.isIp(event.target.value)) {
            setIPColor(good_color);
            setCurrentIP(event.target.value)
            setSearchParams({ip: event.target.value, cidr: cidr});
        } else {
            setIPColor(bad_color);
        }
    }

    const handleCIDRChange = (event) => {
        if(event.target.value > 0 && event.target.value <= 32) {
            setCIDRColor(good_color);
            setCurrentCIDR(event.target.value)
            setSearchParams({ip: ip, cidr: event.target.value});
        } else {
            setCIDRColor(bad_color);
        }
    }

    return (
        <div>
            <div className="ip-input">
                <input onChange={handleIPChange} type="text" className="ip" size="10" defaultValue={props.ip} style={{backgroundColor: ip_color}} id="ip-input" />
                <span></span>&nbsp;/&nbsp;
                <input onChange={handleCIDRChange} type="number" className="cidr" size="1" style={{backgroundColor: cidr_color}} defaultValue={props.cidr} />
            </div>
            <br />
            < Results ip={ip} cidr={cidr} />
        </div>
    )
}

export default IPInput
import React from 'react'
import IPInput from './Calculator/IPInput'
import Header from './Header'
import './App.css'
import {useSearchParams} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  var ip = searchParams.get("ip") != null ? searchParams.get("ip") : "192.168.1.0";
  var cidr = searchParams.get("cidr") != null ? searchParams.get("cidr") : "24";
  
  return (
    <div className='app'>
      < Header sticky="top"/>
      < IPInput ip={ip} cidr={cidr} />
    </div>
  )
}

export default App
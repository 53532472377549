import React from 'react'
import "./Results.css"

var IpSubnetCalculator = require( 'ip-subnet-calculator' );

const Results = (props) => {
    var results = IpSubnetCalculator.calculateSubnetMask( props.ip, props.cidr );
    return (
        <div className="results">
            <div className="results-content">
                Subnet Mask: {results.prefixMaskStr}
                <br />
                Network Address: {results.ipLowStr}
                <br />
                Broadcast Address: {results.ipHighStr}
                <br />
                Host Range: {IpSubnetCalculator.toString(results.ipLow+1)}-{IpSubnetCalculator.toString(results.ipHigh-1)}
                <br />
            </div>
        </div>
    )
}

export default Results